/* Boolean method that returns if an entry start time and entry end time is within a month. Returns true
 * when entry is within a month */
import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import { Client } from '../models/Client';
import { Project } from '../models/project';
import { TimeEntry } from '../models/timeentry';
import roundDuration from '../../business_logic/rounding';
import { TimeRounding } from 'app/shared/enums';
import { UserCompanyRole } from '@cartwheel/web-components';
import { ActivatedRoute } from '@angular/router';
import { QUERY_PARAMS_FOR_INVOICE_FILTER } from 'app/shared/consts';

// dayjs plugins
dayjs.extend(dayjsDuration);

export function entryWithinMonth(startTime: Date, endTime: Date) {
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const firstDay = new Date(y, m, 1);
  const lastDay = new Date(y, m + 1, 0);
  return startTime >= firstDay && endTime <= lastDay;
}

/* Returns the number of hours a user worked for a specific client within 1 month time span */
export function getWeekHours(clientID: string, currentMonthEntries: TimeEntry[], roundingType?: TimeRounding) {
  let hoursWorked = dayjs.duration(0);
  if (currentMonthEntries) {
    for (let i = 0; i < currentMonthEntries.length; i++) {
      const newDuration = getProjectBilledTime(currentMonthEntries[i], roundingType);
      if (newDuration) {
        hoursWorked = hoursWorked.add(newDuration);
      }
    }
  }

  return hoursWorked.asHours();
}

export function updateProjects(client: Client, projects: Project[], entries: TimeEntry[], rate: number, isUserView: boolean) {
  return projects.map(p => {
    const projectEntries = entries.filter(e => e.projectId === p.projectId);
    return {
      ...p,
      projectBilledHours: getHoursWorked(projectEntries, client.clientID, client.timeRounding),
      projectEarnings: getPayOut(rate, projectEntries, client.timeRounding, isUserView)
    }
  })
}

export function getProjectBilledTime(task: TimeEntry, roundingType: TimeRounding) {
  const start = dayjs(new Date(task.startTime));
  const end = task.endTime == null && task.endTime !== undefined ? dayjs(new Date()) : dayjs(new Date(task.endTime));

  const duration = roundDuration(start, end, roundingType);
  return duration;
}

export function getBilledHours(task: TimeEntry): number {
  const start = new Date(task.startTime);
  const end = task.endTime == null && task.endTime !== undefined ? new Date() : new Date(task.endTime);
  const ms = end.getTime() - start.getTime();
  const seconds = ms / 1000;
  return seconds / 3600;
}
export function getPayOut(payRate: number, entries: TimeEntry[], timeRounding: TimeRounding, isUserView: boolean) {
  return entries.reduce((sum, entry) => {
    let rate;

    if (isUserView) {
      rate = (entry.userHourlyRate !== null && entry.userHourlyRate !== undefined) ?
        entry.userHourlyRate : null;
    } else {
      rate = (entry.billedRate !== null && entry.billedRate !== undefined) ?
        entry.billedRate : null;
    }

    if (rate === null) {
      rate = (payRate !== null && payRate !== undefined) ?
        payRate : 0;
    }
    return sum + (rate * getHoursWorked([entry], entry.clientId, timeRounding))
  }, 0)
}

// Updates the month earnings, number of active projects and average month
// hours for the selected client
export function updateEarnings(client: Client, entries: TimeEntry[]): [Client, number] {
  const [hoursWorked, monthEarnings] = entries.reduce(([totalHours, totalEarnings], entry) => {

    const startTime = dayjs(entry.startTime);
    const endTime = dayjs(entry.endTime);

    const timeRounding = (client && client.timeRounding) ? client.timeRounding : TimeRounding.ToNearestMinute;

    const assignedUser = (client && client.assignedUsers) ?
      client.assignedUsers.find((au) => au.userId === entry.createdByUserId) : null;

    const billedRate =
      (entry.billedRate !== null && entry.billedRate !== undefined) ?
        entry.billedRate :
        (assignedUser && assignedUser.billedRate) ?
          assignedUser.billedRate :
          (client && client.billedRate) ?
            client.billedRate : 0;

    const hours = roundDuration(startTime, endTime, timeRounding).asHours();
    const earnings = hours * billedRate;
    return [totalHours + hours, totalEarnings + earnings];
  }, [0, 0]);

  const updatedSelectedClient = { ...client, monthEarnings };
  return [updatedSelectedClient, hoursWorked];
}

export function calculateHoursAndEarnings(client: Client, entries: TimeEntry[], roles: UserCompanyRole[]) {
  return entries.reduce(([totalHours, totalEarnings], entry) => {
    const startTime = dayjs(entry.startTime);
    const endTime = dayjs(entry.endTime);

    const timeRounding = (client && client.timeRounding) ? client.timeRounding : TimeRounding.ToNearestMinute;

    const hours = roundDuration(startTime, endTime, timeRounding).asHours();
    const userRole = roles.find((role) => role.companyId === client.createdByCompanyId);
    if (!userRole && (entry.userHourlyRate === null && entry.userHourlyRate === undefined)) {
      return [totalHours, totalEarnings];
    }

    const payRate = (entry.userHourlyRate !== null && entry.userHourlyRate !== undefined) ?
      entry.userHourlyRate :
      userRole.hourlyRate;

    return [totalHours += hours, totalEarnings += hours * payRate];
  }, [0, 0]);
}

export function updateUserEarnings(client: Client, entries: TimeEntry[], roles: UserCompanyRole[]): [Client, number] {
  const [hoursWorked, monthEarnings] = calculateHoursAndEarnings(client, entries, roles);
  const updatedSelectedClient = { ...client, monthEarnings };
  return [updatedSelectedClient, hoursWorked];
}

export function getHoursWorked(entries: TimeEntry[], clientId: string, timeRounding: TimeRounding): number {
  let hoursWorked = 0;
  if (entries.length > 0) {
    for (let e = 0; e < entries.length; e += 1) {
      if (timeRounding) {
        hoursWorked += roundDuration(
          dayjs(entries[e].startTime),
          dayjs(entries[e].endTime ? entries[e].endTime : new Date()),
          timeRounding
        ).asHours();
      } else {
        hoursWorked += getBilledHours(entries[e]);
      }
    }
  }
  return hoursWorked;
}

export function getMonthRange(): [Date, Date] {
  const dates = new Date(),
    y = dates.getFullYear(),
    m = dates.getMonth();
  const firstDay = new Date(y, m, 1);
  const lastDay = new Date(y, m + 1, 0);
  return [firstDay, lastDay];
}

export function getYearRange(): [Date, Date] {
  const dates = new Date(),
    y = dates.getFullYear(),
    m = dates.getMonth();
  const firstDay = new Date(y, m - 12, 1);
  const lastDay = new Date(y, m, 0);
  return [firstDay, lastDay];
}

/**
 * Returns a formated string
 * @param duration 
 * @param options
 * @returns 
 */
export function formatDuration(
  duration: dayjsDuration.Duration, 
  options: { format: string, trim?: boolean } = {
    format: 'D[d] H[h] m[m]',
    trim: true
  }
): string {
  const splitter = 
    options.format.includes(' ') && " " ||
    options.format.includes('-') && "-" ||
    options.format.includes('/') && "/" ||
    options.format.includes(':') && ":";
    
  const units = options.format.split(splitter);
  const filled_patters: string[] = [];
  const obj_total_durations = {
    D: () => Math.floor(duration.asDays()),
    DD: () => Math.floor(duration.asDays()),
    H: () => Math.floor(duration.asHours()),
    HH: () => Math.floor(duration.asHours()),
    m: () => Math.floor(duration.asMinutes()),
    mm: () => Math.floor(duration.asMinutes()),
  };
  const obj_durations = {
    D: () => duration.days(),
    DD: () => duration.days(),
    H: () => duration.hours(),
    HH: () => duration.hours(),
    m: () => duration.minutes(),
    mm: () => duration.minutes(),
  };

  units.forEach((unit, index) => {
    const suffix = unit.match(/\[(.*?)\]/)?.[1] ?? '';
    const pattern = unit.replace(/\[(.*?)\]/, '');

    const value = index === 0 ? obj_total_durations[pattern]() : obj_durations[pattern]();
    const value_rel = !isNaN(value) ? value : 0;

    if (options.trim && value_rel <= 0) return; 

    filled_patters.push(`${String(value_rel).padStart(pattern.length, '0')}${suffix}`);    
  });

  return filled_patters.join(splitter);
}

export function hasRouteFilterKeys(route: ActivatedRoute): boolean {
  const invoiceFiltersSet = new Set(QUERY_PARAMS_FOR_INVOICE_FILTER);
  return route.snapshot.queryParamMap.keys.some((key) => invoiceFiltersSet.has(key));
}